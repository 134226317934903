<template>
  <div>
    <main class="page-content">
      <div class="account-page-area">
      <div class="">
          <div class="row">
            <div class="col-lg-3 account_border-right">
              <SideMenu></SideMenu>
            </div>
            <div class="col-lg-9">
              <h3>Change Password</h3>
              <ResetPassword></ResetPassword>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import SideMenu from "@/components/sideMenu.vue";
import ResetPassword from "@/components/customer/resetPassword.vue";
export default {
  name: "ChangePassword",
  components: {
    SideMenu,
    ResetPassword     
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
   
  },
};
</script>
